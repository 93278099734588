import React from "react"
import styled from "styled-components"
import { Hero, HeroText, HeroTextBox, HeroP, HeroImage, ContentSectionBlue, SloganBold, SloganBox, SloganBoxReversed, SloganNormal, HeroSlogan, HeroNav } from "../styles/Global"
import Layout from "../layouts";


const TextBox = styled.div`

  display: flex;
  flex-direction: row;
  margin: 0 80px 0 0;
  width: 30%;
  align-self: center;
  @media (max-width: 900px) {
    margin: 0 40px 0 0;
  }
  @media (max-width: 425px) {
    margin: 0;
    width: 80%;
  }
`;

const TextBoxReversed = styled.div`

  display: flex;
  flex-direction: row;
  margin: 0 0 0 80px;
  width: 30%;
  align-self: center;
  justify-content: flex-end;
  @media (max-width: 900px) {
    margin: 0 0 0 40px;
  }
  @media (max-width: 425px) {
    width: 80%;
    margin: 0;
    align-self: center;
  }
`;

const SloganLight = styled(({ reversed, ...props }) => (<h1 {...props} />))`
  text-align: ${({reversed}) => reversed ? `right` : `left`};
  color: #31435A;
  display: block;
  font-size: 60px;
  font-family: ‘Roboto’, sans-serif;
  width: 100%;
  height: auto;
  font-weight: 300;
  margin: 0 0 80px 50px;
  @media (max-width: 1050px) {
    font-size: 35px;
    margin: 0 0 40px 25px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    margin: 0 0 40px 25px;
  }
  @media (max-width: 425px) {
    font-size: 25px;
    margin: 5px 0 10px 0;
    text-align: center;
  }
`;

const Text = styled.h3`
  
  color: ${props => props.Blue ? "#31435A" : "#ffff"};
  text-align: ${props => props.Blue ? "left" : "right"};

  font-family: ‘Roboto’, sans-serif;
  font-size: 24px;
  font-weight: 300;
  @media (max-width: 1050px) {
    font-size: 20px;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 13px;
    text-align: center;
    margin: 0;
  }
`;

const ContentSectionWhite = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffff;
  margin: 0 0 0 0;
  width: 100%;
  height: 550px;
  @media (max-width: 1050px) {
    height: 430px;
  }
  @media (max-width: 900px) {
    height: 280px;
  }
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }
`;


export default function Home() {
  return (
      <Layout>
            <Hero>
                <HeroText>
                    <HeroSlogan>
                        Parking Lot Maintenance
                    </HeroSlogan>
                    <SloganLight>
                        With attention to detail
                    </SloganLight>
                    <HeroNav to={`/#about-us`}>
                        Learn more
                    </HeroNav>
                </HeroText>
            </Hero>
        <ContentSectionBlue id="about-us">
          <SloganBox>
            <SloganBold reversed>
                About Us
            </SloganBold>
            <SloganNormal reversed>
                Spokane Sweeps
            </SloganNormal>
          </SloganBox>
          <TextBox>
                <Text>
                  Our goal is to always provide the best service at the best prices. After serving the Spokane area for over 18 years, our scale has allowed us to pass on the savings to our customers while providing unbeatable quality of service! Our supervisor knows each and every job in the area and takes pride in every single one.
                </Text>
          </TextBox>
        </ContentSectionBlue>
        <ContentSectionWhite>
          <TextBoxReversed>
            <Text Blue>
              All of our drivers and detailers have been with us for between 4 and 10 years. We hired the best, and we keep them around because we know they will do the job right for our customers every time. We never expect anything less than the absolute best for our customers.
            </Text>
          </TextBoxReversed>
          <SloganBoxReversed>
            <SloganBold>
                Only The Best
            </SloganBold>
            <SloganNormal Blue>
                For our customers 
            </SloganNormal>
          </SloganBoxReversed>
        </ContentSectionWhite>
        <ContentSectionBlue reversed>
          <SloganBox>
            <SloganBold reversed>
                Attention to Detail
            </SloganBold>
            <SloganNormal reversed>
                Our signature
            </SloganNormal>
          </SloganBox>
          <TextBox>
                <Text>
                  Every truck that goes out to a job leaves with a minimum of one dedicated detailer, often even 2, to make sure everything is done right. These detailers blow all the edges, store fronts and corners the way it should be done so that your lot always looks it’s best.
                </Text>
          </TextBox>
        </ContentSectionBlue>
        <Hero ImgReversed id="why-important">
          <HeroText reversed>
            <SloganBold Blue>
              Why Is Lot Maintenance
            </SloganBold>
            <SloganLight reversed>
              So important?
            </SloganLight>
            <HeroP>
              There are various benefits to sweeping your parking lot. The collection and removal of paper, leaves and other visible debris is perhaps the most obvious. Equally important, though perhaps less evident, is the removal of metal particles and other hazardous waste left by passing vehicles. Sweeping is also an effective method of removing both large and microscopic pollutants.
            </HeroP>
            <HeroP>
              Keeping outside areas clean is becoming an increasingly important element of the successful business. In todays highly competitive market, business owners can’t afford the tarnished image caused by cutting back on exterior grounds maintenance. Regular maintenance provides many benefits including:
            </HeroP>
            <HeroTextBox>
              <HeroP bold>
                Strong customer first impression •
              </HeroP>
              <HeroP bold>
                Improves life of asphalt and concrete •
              </HeroP>
              <HeroP bold>
                Removal of litter reduces risk of slip and fall injuries and lawsuits •
              </HeroP>
            </HeroTextBox>
          </HeroText>
        </Hero>
        </Layout>
  );
}
